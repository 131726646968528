export enum FileTypes {
	js = 'JS',
	css = 'CSS',
	html = 'HTML',
	md = 'MD',
	py = 'PY',
	png = 'PNG',
	jpg = 'JPEG',
	gif = 'GIF',
}
