import { getTemplateCloneById, Logger, throws } from '../../util';
import DOMPurify from 'dompurify';
import { FileTypes } from '../../models/types';
import { marked } from 'marked';
import { ProjectFile } from '../project';

const PREVIEW_FRAME_REFRESH_TIMEOUT = 23;
export class MdPreview extends HTMLElement {
	protected selectedFile: ProjectFile = {} as never;
	protected frame: HTMLIFrameElement = document.createElement('iframe');
	constructor() {
		super();
		this.classList.add('col-12');
		this.appendChild(getTemplateCloneById('preview-md-toolbar'));
	}

	public connectedCallback() {
		this.hidden = true;
		this.frame = this.frame ?? this.ownerDocument?.createElement('iframe') ?? throws.noOwnerDocument(this);
		this.appendChild(this.frame);
    }

    public updateFrame(file: ProjectFile, files: ProjectFile[]) {
		this.frame.contentWindow?.location.reload();
		setTimeout(() => this.loadFiles(file, files), PREVIEW_FRAME_REFRESH_TIMEOUT);
    }

    private loadFiles(file: ProjectFile, files: ProjectFile[]) {
		const { Path, Name, Type } = file;

		if (Type !== FileTypes.md) {
			throw new Error('File must be of type MD');
		}
		if (Path == null) {
			Logger.Warn(`Path null for file ${file.Id}`);
			return;
		}
		if (Name == null) {
			Logger.Warn(`Name null for file ${file.Id}`);
			return;
		}

		const md = files
			.filter(x => x.Type === FileTypes.md)
			.filter(x => x.Path === Path)
			.find(x => x.Name === Name);

		if (md == null) {
			return;
		}

		const frameDoc = this.frame.contentDocument ?? throws.noContentDocument(this.frame);
		const element = frameDoc.body;
		element.style.color = 'white';
		element.innerHTML = DOMPurify.sanitize(marked.parse(md.Text));

		setTimeout(() => {
			if (this.frame.contentDocument !== frameDoc) {
				this.updateFrame(file, files);
			}
		});
	}
}