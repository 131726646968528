import 'skulpt/dist/skulpt.js';
import 'skulpt/dist/skulpt-stdlib.js';

import { Editor, FileModal, FileSystem, HtmlPreview, Landing, LoginModal, MdPreview, Notification, Notifier, ProfileModal, Project, PythonPreview, SignUpModal, Terminal } from './components';
import { Http, Logger, Router, ShortTermStorage, defineCustomElements, getElementById, getEventTargetAnchor, querySelector } from './util';
import { User, quotes } from './models';

const { Sk: python } = globalThis;
python.TurtleGraphics = python.TurtleGraphics ?? {} as never;
const { TurtleGraphics } = python;

const main = querySelector(document, 'body > main');
const loading = querySelector(document, 'body > main > aside');

const LANDING_TRANSITION_DELAY = 100;
async function hideLoadingScreenAsync() {
	return new Promise((r, _) => setTimeout(() => r(loading.hidden = true), LANDING_TRANSITION_DELAY));
}

class Application extends HTMLElement {
	private readonly notifier = new Notifier();
	private readonly router = new Router(globalThis.history);
	private readonly shortTermStorage = new ShortTermStorage(1);

	private readonly modals = {
		signup: new SignUpModal(),
		login: new LoginModal(),
		profile: new ProfileModal(),
		file: new FileModal(),
	};

	private readonly previews = {
		html: new HtmlPreview(),
		python: new PythonPreview(),
		md: new MdPreview(),
	};

	private readonly landing = new Landing();
	private readonly fs = new FileSystem(this.shortTermStorage, this.modals.file);
	private readonly editor = new Editor(this.shortTermStorage);
	private readonly terminal = new Terminal(python);
	private readonly project = new Project(
		this.fs,
		this.editor,
		this.previews.html,
		this.previews.md,
		this.previews.python,
		this.terminal,
		this.modals.file,
		this.notifier,
		this.router,
	);

	private _user = this.shortTermStorage.get<User>('user') ?? new User();

	private get user() {
		return this._user;
	}

	private set user(user) {
		this.shortTermStorage.set('user', user);
		this._user = user;
	}

	constructor() {
		super();

		this.appendChild(this.landing);
		this.appendChild(this.project);
		this.appendChild(this.modals.signup);
		this.appendChild(this.modals.login);
		this.appendChild(this.modals.profile);
		this.appendChild(this.notifier);

		this.landing.hidden = true;
		this.project.hidden = true;
	}

	public async connectedCallback() {
		this.updateQuote();

		setTimeout(() => {
			TurtleGraphics.target = getElementById('python-preview-output');
		});

		// hijack <a> element clicks
		globalThis.onclick = async e => {
			const target = getEventTargetAnchor(e);

			if (target instanceof HTMLAnchorElement) {
				const href = target.getAttribute('href');
				if (typeof href === 'string') {
					if (href.startsWith(process.env.API_HOST) || href.startsWith('mailto:')) {
						return;
					}

					e.preventDefault();

					this.landing.hidden = true;
					loading.hidden = false;

					const isLanding = location.pathname === '/';
					if (isLanding && href !== '/') {
						await this.project.loadFromUrl(href);
						hideLoadingScreenAsync(); // we want the loading screen to persist until after we unhide the project
					} else {
						if (href === '/') {
							this.router.go({ title: 'CodeStack Editor', url: href });
						} else {
							await this.project.loadFromUrl(href);
						}
						await hideLoadingScreenAsync();
					}

					this.project.hidden = href === '/';
					this.landing.hidden = this.project.hidden === false;
				}
			}
		};

		// handle browser back/forward button
		globalThis.onpopstate = async () => {
			this.landing.hidden = true;
			loading.hidden = false;

			const isLanding = location.pathname === '/';
			if (isLanding) {
				await hideLoadingScreenAsync();
			} else {
				await this.project.loadFromUrl(location.pathname);
				hideLoadingScreenAsync(); // we want the loading screen to persist until after we unhide the project
			}

			this.landing.hidden = isLanding === false;
			this.project.hidden = isLanding === true;
		};

		// resize editor with screen
		globalThis.onresize = () => {
			requestAnimationFrame(() => this.project.editor.resize());
		};

		let isAuthenticated = false;
		let isOffline = false;

		try {
			isAuthenticated = await Http.Get('user/isAuthenticated');
			if (isAuthenticated && this.user.Id === 0) {
				this.user = await Http.Get('user/profile');
			}
		} catch (err: any) {
			isOffline = err.message === 'Could not connect to the server.';
		}

		if (isOffline) {
			alert('This application does not yet support offline mode.');
		}

		const footer = querySelector(document, 'body > footer');
		footer.onclick = () => {
			this.updateQuote();
		};

		const signupBtn = querySelector<HTMLButtonElement>(document, '#signup');
		const loginBtn = querySelector<HTMLButtonElement>(document, '#login');
		const profileBtn = querySelector<HTMLButtonElement>(document, '#profile');
		const logoutBtn = querySelector<HTMLButtonElement>(document, '#logout');

		function disableHeaderButtons() {
			signupBtn.disabled = true;
			loginBtn.disabled = true;
			profileBtn.disabled = true;
			logoutBtn.disabled = true;
		}
		function enableHeaderButtons() {
			signupBtn.disabled = false;
			loginBtn.disabled = false;
			profileBtn.disabled = false;
			logoutBtn.disabled = false;
		}
		function showAuthenticatedButtons() {
			loginBtn.hidden = true;
			signupBtn.hidden = true;
			profileBtn.hidden = false;
			logoutBtn.hidden = false;
		}
		function showUnauthenticatedButtons() {
			loginBtn.hidden = false;
			signupBtn.hidden = false;
			profileBtn.hidden = true;
			logoutBtn.hidden = true;
		}

		signupBtn.onclick = async () => {
			const signupRequestGenerator = this.modals.signup.open();
			disableHeaderButtons();

			try {
				for await (const value of signupRequestGenerator) {
					if (value instanceof Error) {
						return Logger.Error(value.message);
					}

					try {
						await Http.Post('user/signup', value);
						this.notifier.success('Oh, so  *that\'s* your name. Welcome!');
						signupRequestGenerator.return(value);
					} catch (err: any) {
						this.notifier.error(err);
					}
				}

				this.modals.signup.close();
				showAuthenticatedButtons();
			} finally {
				enableHeaderButtons();
			}
		};

		loginBtn.onclick = async () => {
			const loginRequestGenerator = this.modals.login.open();
			disableHeaderButtons();

			try {
				for await (const value of loginRequestGenerator) {
					if (value instanceof Error) {
						return Logger.Error(value.message);
					}

					try {
						this.user = await Http.Post('user/login', value);
						this.notifier.success('Logged in');
						loginRequestGenerator.return(value);
					} catch (err: any) {
						this.notifier.error(err.toString());
					}
				}

				this.modals.login.close();
				showAuthenticatedButtons();
			} finally {
				enableHeaderButtons();
			}
		};

		querySelector<HTMLButtonElement>(document, '#login-landing').onclick = loginBtn.onclick;
		querySelector<HTMLButtonElement>(document, '#signup-landing').onclick = signupBtn.onclick;

		profileBtn.onclick = async () => {
			const profileViewGenerator = this.modals.profile.open(this.user);
			disableHeaderButtons();

			try {
				for await (const value of profileViewGenerator) {
					if (value instanceof Error) {
						return Logger.Error(value.message);
					}
					profileViewGenerator.return(value);
				}

				this.modals.profile.close();
			} finally {
				enableHeaderButtons();
			}
		};

		logoutBtn.onclick = async () => {
			try {
				await Http.Post('user/logout');
				this.shortTermStorage.remove('user');
				this.notifier.success('Logged out');
				this.router.go({ title: 'CodeStack Editor', url: '/' });
				this.landing.hidden = false;
				this.project.hidden = true;
				showUnauthenticatedButtons();
			} catch {
				this.notifier.error('Unable to log out');
			}
		};

		const newProjectBtn = querySelector(this.landing, '#new-project');
		newProjectBtn.onclick = async () => {
			const project = await this.project.create();
			this.user.Projects.push(project);
			this.shortTermStorage.set('user', this.user);
			this.landing.hidden = true;
			this.project.hidden = false;
		};

		if (isAuthenticated) {
			showAuthenticatedButtons();
		} else {
			showUnauthenticatedButtons();
		}

		if (location.pathname != null && location.pathname !== '/') {
			await this.project.loadFromUrl(location.pathname);
			this.landing.hidden = true;
			this.project.hidden = false;
		} else {
			this.landing.hidden = false;
		}

		loading.hidden = true;
	}

	public disconnectedCallback() {
		this.landing.hidden = true;
		this.project.hidden = true;

		loading.hidden = false;
		globalThis.onpopstate = null;
		querySelector(this.landing, '#new-project').onclick = null;
	}

	public updateQuote() {
		const { quote, by } = quotes[Math.floor(Math.random() * quotes.length)];
		querySelector(document, '#quote').innerText = quote;
		querySelector(document, '#cite').innerText = by;
	}
}

defineCustomElements(
	Application,
	Landing,
	Project,
	FileSystem,
	Editor,
	HtmlPreview,
	PythonPreview,
	MdPreview,
	Terminal,
	Notification,
	Notifier,
	FileModal,
	SignUpModal,
	LoginModal,
	ProfileModal,
);

main.appendChild(new Application());
