import debounce from 'lodash/debounce';

import { ProjectFile } from './file';

import { Editor, EditorThemes } from '../editor';
import { FileSystem } from '../fs';
import { FileModal } from '../modal';
import { Notifier } from '../notify';
import { HtmlPreview, MdPreview, PythonPreview } from '../previews';
import { Terminal } from '../terminal';

import { FileTypes, User } from '../../models';
import { GlobalStation, Http, Logger, Router, getTemplateCloneById, querySelector } from '../../util';

const PREVIEW_MODEL_UPDATE_DEBOUNCE = 500;

export class Project extends HTMLElement {
	/**
	 * Generated by server
	 */
	public readonly Id = 0;

	/**
	 * Generated by server
	 */
	public readonly Version = 0;

	/**
	 * Generated by server
	 */
	public readonly LatestVersion: number | null = null;

	/**
	 * Generated by server
	 */
	public readonly IsReadOnly = false;

	/**
	 * Generated by server
	 */
	public readonly LastModified: Date = new Date();

	public get IsNotLatestVersion() {
		return this.LatestVersion != null && this.LatestVersion > this.Version;
	}

	public Timestamp = new Date();
	public Name = '';
	public URL = '';
	public User: User | null = null;
	public Files: ProjectFile[] = [];

	protected readonly observer: MutationObserver;
	protected selectedHtmlFile: ProjectFile | null = null;
	protected selectedMdFile: ProjectFile | null = null;

	private newFileButton: HTMLButtonElement;
	private saveButton: HTMLButtonElement;
	private currentVersionSpan: HTMLElement;
	private latestVersionLink: HTMLAnchorElement;

	private terminalButton: HTMLButtonElement;
	private htmlPreviewButton: HTMLButtonElement;
	private mdPreviewButton: HTMLButtonElement;
	private infoButton: HTMLButtonElement;
	private copyUrlButton: HTMLButtonElement;
	private themeSelector: HTMLSelectElement;

	private htmlPreviewSelector: HTMLSelectElement;
	private mdPreviewSelector: HTMLSelectElement;
	private refreshPreviewButton: HTMLButtonElement;
	private refreshMdPreviewButton: HTMLButtonElement;
	private togglePythonPreviewOutputBtn: HTMLButtonElement;
	private runPythonBtn: HTMLButtonElement;

	private infoMenu: HTMLElement;
	private projectOwnerAddress: HTMLElement;
	private projectCreatedTime: HTMLElement;
	private projectModifiedTime: HTMLElement;
	private projectEmailTo: HTMLAnchorElement;
	private projectDownload: HTMLAnchorElement;
	private urlInput: HTMLInputElement;
	private previewContainer: HTMLDivElement;
	private previewRow: HTMLDivElement;

	constructor(
		public fs: FileSystem,
		public editor: Editor,
		public htmlPreview: HtmlPreview,
		public mdPreview: MdPreview,
		public pythonPreview: PythonPreview,
		public terminal: Terminal,
		protected fileModal: FileModal,
		protected notifier: Notifier,
		protected router: Router,
	) {
		super();

		this.classList.add('row');
		this.classList.add('g-0');

		this.newFileButton = querySelector<HTMLButtonElement>(this.fs, '#new-file');
		this.newFileButton.appendChild(getTemplateCloneById('file-add-icon'));

		this.saveButton = querySelector<HTMLButtonElement>(this.fs, '#save');
		this.saveButton.appendChild(getTemplateCloneById('save-icon'));

		this.currentVersionSpan = querySelector<HTMLElement>(this.fs, '#current-version');
		this.latestVersionLink = querySelector<HTMLAnchorElement>(this.fs, '#latest-version');

		this.terminalButton = querySelector<HTMLButtonElement>(this.editor, '#toggle-terminal');
		this.terminalButton.appendChild(getTemplateCloneById('terminal-icon'));

		this.htmlPreviewButton = querySelector<HTMLButtonElement>(this.editor, '#toggle-html-preview');
		this.htmlPreviewButton.appendChild(getTemplateCloneById('preview-icon'));

		this.mdPreviewButton = querySelector<HTMLButtonElement>(this.editor, '#toggle-md-preview');
		this.mdPreviewButton.appendChild(getTemplateCloneById('question-icon'));

		this.infoButton = querySelector<HTMLButtonElement>(this.editor, '#toggle-project-info');
		this.infoButton.appendChild(getTemplateCloneById('info-icon'));

		this.refreshPreviewButton = querySelector<HTMLButtonElement>(this.htmlPreview, '#refresh-preview');
		this.refreshPreviewButton.appendChild(getTemplateCloneById('refresh-icon'));

		this.refreshMdPreviewButton = querySelector<HTMLButtonElement>(this.mdPreview, '#refresh-md-preview');
		this.refreshMdPreviewButton.appendChild(getTemplateCloneById('refresh-icon'));

		this.copyUrlButton = querySelector<HTMLButtonElement>(this.editor, '#copy-url');
		this.themeSelector = querySelector<HTMLSelectElement>(this.editor, '#theme');

		this.htmlPreviewSelector = querySelector<HTMLSelectElement>(this.htmlPreview, '#html-preview');
		this.mdPreviewSelector = querySelector<HTMLSelectElement>(this.mdPreview, '#md-preview');
		this.togglePythonPreviewOutputBtn = querySelector<HTMLButtonElement>(this.pythonPreview, '#toggle-python-preview-output');
		this.runPythonBtn = querySelector<HTMLButtonElement>(this.pythonPreview, '#run-python');

		this.infoMenu = querySelector(this.editor, '#info');
		this.projectOwnerAddress = querySelector(this.infoMenu, '#project-owner');
		this.projectCreatedTime = querySelector(this.infoMenu, '#project-created');
		this.projectModifiedTime = querySelector(this.infoMenu, '#project-modified');
		this.projectEmailTo = querySelector(this.infoMenu, '#project-mailto');
		this.projectDownload = querySelector(this.infoMenu, '#project-download');
		this.urlInput = querySelector<HTMLInputElement>(this.infoMenu, 'input');

		this.previewContainer = document.createElement('div');
		this.previewRow = document.createElement('div');

		this.observer = new MutationObserver(records => {
			if (records.some(x => x.addedNodes.length > 0) && this.pythonPreview.hidden) {
				this.pythonPreview.hidden = false;
				this.togglePythonPreviewOutputBtn.click();
			}
		});
	}

	public connectedCallback() {
		this.appendChild(this.fs);
		this.appendChild(this.editor);
		this.previewContainer.classList.add('col');
		this.previewRow.classList.add('row');
		this.previewRow.classList.add('h-100');
		this.appendChild(this.previewContainer);
		this.previewContainer.appendChild(this.previewRow);
		this.previewRow.appendChild(this.htmlPreview);
		this.previewRow.appendChild(this.mdPreview);
		this.appendChild(this.pythonPreview);
		this.appendChild(this.terminal);
		this.appendChild(this.fileModal);

		this.observer.observe(querySelector(this, '#python-preview-output'), { childList: true });

		this.newFileButton.onclick = async () => {
			if (this.IsNotLatestVersion) {
				return this.notifier.info('Unable to add file as a newer project version exists.');
			}

			try {
				const fileGenerator = this.fileModal.open({ Path: this.fs.currentPath });

				for await (const file of fileGenerator) {
					if (file instanceof Error) {
						return Logger.Error(file.message);
					}

					try {
						const { el } = this.fs.addFile(file);
						this.Files.push(file);

						if (file.Type === FileTypes.html) {
							this.updateHtmlPreviewSelector();
							if (this.Files.filter(x => x.IsDeleted !== true).some(x => x.Type === FileTypes.css) === false) {
								const styleFile = new ProjectFile({ Name: 'style', Ext: 'css' });
								styleFile.Text = FileSystem.GetDefaultText(styleFile);
								this.fs.addFile(styleFile);
								this.Files.push(styleFile);
							}
						}

						if (file.Type === FileTypes.md) {
							this.updateMdPreviewSelector();
						}

						this.saveButton.disabled = false;
						el.click();
						fileGenerator.return(file);
					} catch (err: any) {
						this.saveButton.disabled = this.Files.every(x => x.Id > 0 && x.IsModified !== true);
						Logger.Error(err);
						alert(err.message);
					}
				}

				this.fileModal.close();
			} catch (e) {
				Logger.Error(e);
				return this.fileModal.close();
			}
		};
		this.saveButton.onclick = () => {
			if (this.IsNotLatestVersion) {
				return this.notifier.info('Unable to save as a newer version exists.');
			}
			this.saveAndLoad();
		};

		this.terminalButton.onclick = () => {
			this.terminalButton.classList.toggle('active');
			this.terminal.hidden = !this.terminal.hidden;
		};
		this.htmlPreviewButton.onclick = () => {
			this.htmlPreviewButton.classList.toggle('active');
			this.htmlPreview.hidden = !this.htmlPreview.hidden;
			this.togglePreviewContainer();
		};
		this.mdPreviewButton.onclick = () => {
			this.mdPreviewButton.classList.toggle('active');
			this.mdPreview.hidden = !this.mdPreview.hidden;
			this.togglePreviewContainer();
		};
		this.infoButton.onclick = () => {
			this.infoButton.classList.toggle('active');
			this.infoMenu.hidden = !this.infoMenu.hidden;
		};
		this.copyUrlButton.onclick = () => {
			this.urlInput.select();
			document.execCommand('copy');
			alert('Copied project URL to clipboard');
		};

		for (const option of this.themeSelector.options) {
			option.selected = this.editor.isThemeActive(option.value as EditorThemes);
		}
		this.themeSelector.onchange = e => {
			this.editor.theme = (e.target as HTMLOptionElement).value as EditorThemes;
		};

		this.refreshPreviewButton.onclick = () => {
			if (this.selectedHtmlFile == null) {
				return;
			}
			this.htmlPreview.updateFrame(this.selectedHtmlFile, this.Files);
		};

		this.refreshMdPreviewButton.onclick = () => {
			if (this.selectedMdFile == null) {
				return;
			}
			this.mdPreview.updateFrame(this.selectedMdFile, this.Files);
		};

		this.htmlPreviewSelector.onchange = e => {
			const file = JSON.parse((e.target as HTMLOptionElement).value);
			this.selectHtmlFile(file);
		};

		this.mdPreviewSelector.onchange = e => {
			const file = JSON.parse((e.target as HTMLOptionElement).value);
			this.selectMdFile(file);
		};

		this.togglePythonPreviewOutputBtn.onclick = () => {
			if (globalThis.Sk.TurtleGraphics?.target instanceof HTMLElement) {
				const { target } = globalThis.Sk.TurtleGraphics;
				target.hidden = !target.hidden;
			}
		};
		this.runPythonBtn.onclick = () => {
			const { currentFile } = this.editor;
			if (currentFile.Type === FileTypes.py) {
				if (this.terminal.hidden) {
					this.terminalButton.click();
				}
				/**
				 * I am not proud of this. One of us should Do The Right Thing™ here.
				 */
				const ARBITRARY_WAIT_TO_AVOID_MULTIPLE_DOLLAR_SIGNS_IN_TERMINAL = 100;
				setTimeout(
					() => this.terminal.write(`py ${FileSystem.GetFullPath(currentFile)}`),
					ARBITRARY_WAIT_TO_AVOID_MULTIPLE_DOLLAR_SIGNS_IN_TERMINAL,
				);
			}
		};
	}

	public disconnectedCallback() {
		this.observer.disconnect();

		this.removeChild(this.fs);
		this.removeChild(this.editor);
		this.removeChild(this.htmlPreview);
		this.removeChild(this.mdPreview);
		this.removeChild(this.pythonPreview);
		this.removeChild(this.terminal);
		this.removeChild(this.fileModal);

		this.newFileButton.onclick = null;
		this.saveButton.onclick = null;
		this.terminalButton.onclick = null;
		this.htmlPreviewButton.onclick = null;
		this.mdPreviewButton.onclick = null;
		this.infoButton.onclick = null;
		this.copyUrlButton.onclick = null;
		this.themeSelector.onclick = null;
		this.togglePythonPreviewOutputBtn.onclick = null;
	}

	public load(project: Project) {
		this.unload();

		(this as any).Id = project.Id;
		(this as any).Version = project.Version;
		(this as any).LatestVersion = project.LatestVersion;
		(this as any).IsReadOnly = project.IsReadOnly;
		(this as any).LastModified = new Date(project.LastModified);

		this.Timestamp = new Date(project.Timestamp);
		this.Name = project.Name;
		this.URL = project.URL;
		this.User = project.User;
		this.Files = project.Files;

		this.newFileButton.hidden = this.IsNotLatestVersion || this.IsReadOnly;
		this.saveButton.hidden = this.newFileButton.hidden;

		// tslint:disable-next-line: no-non-null-assertion
		this.currentVersionSpan.parentElement!.hidden = !this.newFileButton.hidden;
		this.currentVersionSpan.innerText = `Project Version: ${this.Version}`;
		if (this.IsReadOnly) {
			this.latestVersionLink.hidden = true;
		} else {
			this.latestVersionLink.innerHTML = `Go to Latest Version (${project.LatestVersion}) &raquo;`;
			this.latestVersionLink.href = `/${project.URL}?v=${project.LatestVersion}`;
		}

		if (this.User != null) {
			this.projectEmailTo.hidden = false;
			this.projectEmailTo.href = `mailto:${this.User.Email}`;
			this.projectDownload.hidden = false;
			this.projectDownload.href = Http.MakeUrlFromEndpoint(`project/zip/${this.URL}`);
			this.projectOwnerAddress.innerText = this.User.FullName.trim() || 'Unknown';
		}

		this.projectCreatedTime.innerText = this.Timestamp.toString();
		this.projectCreatedTime.setAttribute('datetime', this.Timestamp.toISOString());
		this.projectModifiedTime.innerText = this.LastModified.toString();
		this.projectModifiedTime.setAttribute('datetime', this.LastModified.toISOString());

		GlobalStation.subscribe(this.radio);
		this.fs.addFiles(this.Files);
		this.editor.onContentChange(debounce(f => {
			const path = FileSystem.GetFullPath(f);
			const file = this.Files.find(x => FileSystem.GetFullPath(x) === path);
			if (file != null) {
				// handle json/non-class file representation
				if (file.OriginalText == null) {
					(file as any).OriginalText = file.Text;
				}
				file.Text = f.Text;
				file.IsModified = file.Text !== file.OriginalText;
			}
			this.saveButton.disabled = this.Files.every(x => x.IsModified !== true || x.Id === 0);
		}, PREVIEW_MODEL_UPDATE_DEBOUNCE));

		// location.href can be slow to update
		// should be safe since the copy button is the input's only interaction point
		setTimeout(() => {
			this.urlInput.value = location.href;
			this.urlInput.size = this.urlInput.value.length;
		});
		this.updateHtmlPreviewSelector();
		this.updateMdPreviewSelector();

		const state = {
			data: { version: this.Version },
			title: this.Name.length > 0 ? this.Name : 'Unnamed Project',
			url: `${this.URL}?v=${this.Version}`,
		};

		const { version } = this.router.stateParams ?? {};
		if (location.pathname === `/${this.URL}` && Number.isInteger(version) === false) {
			this.router.update(state);
		} else if (version !== this.Version) {
			this.router.go(state);
		}
	}

	public async loadFromUrl(url: string) {
		const version = new URLSearchParams(location.search).get('v');

		let endpoint = `/project${url}`;
		if (location.pathname.endsWith(url) && version != null) {
			endpoint += `?v=${version}`;
		}

		const data = await Http.Get(endpoint);
		this.load(data);
	}

	public async create() {
		const data = await Http.Post('/project/create');
		this.load(data);
		return data;
	}

	public async saveAndLoad() {
		const eligibleFiles = this.Files.filter(x => (x.IsModified === true || x.Id === 0));
		if (eligibleFiles.length === 0) {
			return this.notifier.info('Nothing to save.');
		}

		try {
			this.saveButton.disabled = true;

			const version = new URLSearchParams(location.search).get('v');
			const save = await Http.Post(`project/${this.URL}?v=${version}`, eligibleFiles);

			this.notifier.success('Saved');
			this.load(save);
		} catch (err: any) {
			this.saveButton.disabled = false;
			this.notifier.error(`Unable to save: ${err.message}`);
		}
	}

	public unload() {
		(this as any).Id = 0;
		(this as any).Version = 0;
		(this as any).LatestVersion = 0;
		(this as any).LastModified = new Date();

		this.Timestamp = new Date();
		this.Name = '';
		this.URL = '';
		this.Files = [];

		this.projectEmailTo.hidden = true;
		this.projectEmailTo.href = '';

		this.projectOwnerAddress.innerText = 'Anonymous';
		this.projectCreatedTime.innerText = '';
		this.projectCreatedTime.removeAttribute('datetime');

		this.selectedHtmlFile = null;
		this.selectedMdFile = null;

		GlobalStation.unsubscribe(this.radio);
		this.fs.dispose();
		this.editor.dispose();
		this.terminal.dispose();

		this.terminalButton.classList.remove('active');
	}

	public getFileByFullPath(path: string) {
		return this.Files.find(x => FileSystem.GetFullPath(x) === path);
	}

	public updateHtmlPreviewSelector() {
		this.clearHtmlPreviewSelector();

		for (const file of this.Files.filter(x => x.Type === FileTypes.html)) {
			if (this.selectedHtmlFile == null) {
				this.selectHtmlFile(file);
			}

			const option = document.createElement('option');
			option.innerText = FileSystem.GetFullPath(file);
			option.value = JSON.stringify(file);
			this.htmlPreviewSelector.appendChild(option);
		}
	}

	private clearHtmlPreviewSelector() {
		while (this.htmlPreviewSelector.firstElementChild != null) {
			this.htmlPreviewSelector.firstElementChild.remove();
		}
	}

	public updateMdPreviewSelector() {
		this.clearMdPreviewSelector();

		for (const file of this.Files.filter(x => x.Type === FileTypes.md)) {
			if (this.selectedMdFile == null) {
				this.selectMdFile(file);
			}

			const option = document.createElement('option');
			option.innerText = FileSystem.GetFullPath(file);
			option.value = JSON.stringify(file);
			this.mdPreviewSelector.appendChild(option);
		}
	}

	private clearMdPreviewSelector() {
		while (this.mdPreviewSelector.firstElementChild != null) {
			this.mdPreviewSelector.firstElementChild.remove();
		}
	}

	private radio = (code: string, message: any) => {
		switch (code) {
			case 'file-select':
				if (message as ProjectFile != null) {
					if ([
						FileTypes.html,
						FileTypes.css,
						FileTypes.js,
						FileTypes.py,
						FileTypes.md,
					].includes(message.Type)) {
						this.editor.openFile(message);
						this.pythonPreview.hidden = message.Type !== FileTypes.py;
						this.saveButton.disabled = this.Files.every(x => x.Id > 0 && x.IsModified !== true);
					}
				}
				break;
			default:
				// do nothing
				break;
		}
	}

	private selectHtmlFile(file: ProjectFile) {
		this.selectedHtmlFile = file;
		this.htmlPreview.updateFrame(file, this.Files);
	}

	private selectMdFile(file: ProjectFile) {
		this.selectedMdFile = file;
		this.mdPreview.updateFrame(file, this.Files);
	}

	private togglePreviewContainer() {
		if (this.mdPreview.hidden && this.htmlPreview.hidden)
		{
			this.previewContainer.hidden = true;
			this.editor.classList.add('col-md-9');
			this.editor.classList.add('col-10');
			this.editor.classList.remove('col-5');
		} else {
			this.previewContainer.hidden = false;
			this.editor.classList.remove('col-md-9');
			this.editor.classList.remove('col-10');
			this.editor.classList.add('col-5');
		}
		this.editor.resize();
	}
}
